/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// assets app.js




import './fontawesome/fontawesome-webfont.eot';
import './fontawesome/font-awesome.css';

// const $ = require('jquery');

// create global $ and jQuery variables
// global.$ = global.jQuery = $;

// window.$ = window.jQuery = $;

// require('bootstrap');

// main script
//import './tarteaucitron/tarteaucitron.js';
// site configuration
//import './tarteaucitron/tarteaucitronprgiot.js';

// start the Stimulus application js then css
import './bootstrap.js';
// import "bootstrap.bundle.min.js" from './bootstrap';
import './bootstrap/bootstrap.min.css';



import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

window.Stimulus = Application.start()
const context = require.context("./controllers", true, /\.js$/)
// const context = require.context("./controllers.json")
Stimulus.load(definitionsFromContext(context))


import { startStimulusApp } from '@symfony/stimulus-bridge';

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';
import '..//public/css/stylesheet.css';


export const app = startStimulusApp(require.context(
    '@symfony/stimulus-bridge/lazy-controller-loader!./controllers',
    true,
    /\.(j|t)sx?$/
));

